


.servicesTitle{
  font-size: 35px;
  font-weight: 800;
  color: whitesmoke;
}


.servicesContainer{
  display: flex;
  flex-direction: column;
  font-family: 'Oxygen', sans-serif;
  margin-left: 4vh;
}

.dataContainer table{
  /* border: 2px solid black; */
  /* margin-top: 10vh; */
  margin-left: 5vh;
  margin-right: 5vh;
  background-color: rgb(37, 37, 37,0.9);
  /* padding: 10px; */
  color: whitesmoke;

}
.indService{
  margin-bottom: 10px;
}
.category{
  border: 2px solid black;
  padding: 5px;
}

.dataPoint{
  border: 2px solid black;
  padding: 5px;
}
.otherTitle{
  font-size: 16px;
  font-weight: 700;
}
.otherDetails{
  margin-top: 20px;
  margin-left: 5vh;
  margin-right: 5vh;
  background-color: rgb(37, 37, 37,0.9);
  color: whitesmoke;
  padding: 10px;
  border: 2px solid black;
  font-family: 'Oxygen', sans-serif;
  width: fit-content;
}


@media (max-width: 640px) {
  .servicesContainer {
    margin-left: 1vh;
  }
  .dataContainer table,
  .otherDetails {
    margin-left: 1vh;
    margin-right: 1vh;
  }
  .category, .dataPoint {
    padding: 2px;
    font-size: 12px;
  }
  .otherTitle {
    font-size: 14px;
  }
}


.dataContainer {
  overflow-x: auto;
}
