@tailwind base;
@tailwind components;
@tailwind utilities;

body, html, #root {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.custom-bg {
  background-image: url('https://d2e39dj8x3uchh.cloudfront.net/barrels1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
