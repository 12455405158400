.contactContainer{
  display: flex;
  flex-direction: column;
  font-family: 'Oxygen', sans-serif;
  align-items: center;
  justify-content: center;

}
.contactTitle{
  font-size: 35px;
  font-weight: 800;
  color: whitesmoke;
  margin-top: -15px;
}

.contactInfo{
  margin-top: 2vh;
  background-color: rgb(37, 37, 37,0.9);
  margin-right: 1vh;
  margin-left: 1vh;
  padding: 30px;
  color: whitesmoke;
  border-radius: 15px;
}

.contactInfo a{
  color: hsla(358, 94%, 45%);
  margin-left: 5px;
}
